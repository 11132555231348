<template>
  <div v-if="project" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />
    <SalesTimeline :project="project" />
    <b-card-group deck>
      <SalesCardState :project="project" :projectNumber="projectNumberByClientContext" />
      <SalesCardOverview :project="project" :projectNumber="projectNumberByClientContext" />
      <ProjectCardCommunication :project="project" />
    </b-card-group>

    <b-tabs content-class="" fill v-model="tabIndex">
      <b-tab :title="$t('customer')">
        <SalesCustomer :project="project" />
      </b-tab>
      <b-tab :title="$t('SALES_PHASE_PLANNING')">
        <SalesPlanning :project="project" />
      </b-tab>
      <!-- <b-tab :title="$t('appointments')">
        <SalesAppointments :project="project" />
      </b-tab> -->
      <b-tab :title="$t('email')">
        <SalesEmail :project="project" />
      </b-tab>
      <b-tab :title="$t('documents')">
        <SalesDocuments :project="project" />
      </b-tab>
      <!-- <b-tab :title="$t('History')">
        <SalesHistory :project="project" />
      </b-tab> -->

      <b-row>
        <b-col lg="12" md="12" sm="12">
          <ButtonBar
            :showPreviousButton="buttons.showPreviousButton"
            :showNextButton="buttons.showNextButton"
            :showDeleteButton="buttons.showDeleteButton"
            :model="project"
            modelRouteParamName="projectNumber"
            :nextModelNumber="project.nextProjectNumber"
            :previousModelNumber="project.previousProjectNumber"
            :updateCallback="updateProject"
            :createCallback="createProject"
            :deleteCallback="deleteProject"
            :fetchCallback="fetchProjects"
          />
        </b-col>
      </b-row>
    </b-tabs>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import { yesNo } from '@/helpers/enums';
import { getYesNoStateColor, getProjectStateColor } from '@/helpers/colors';
import { solarPlantOverallPower } from '@/helpers/projects';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import SalesCardOverview from '@/views/Sales/SalesCardOverview';
import SalesCustomer from '@/views/Sales/SalesCustomer';
import ButtonBar from '@/components/ButtonBar.vue';
import SalesDocuments from '@/views/Sales/SalesDocuments';
import SalesTimeline from '@/views/Sales/SalesTimeline';
import SalesEmail from '@/views/Sales/SalesEmail';
// import SalesHistory from '@/views/Sales/SalesHistory';
// import SalesAppointments from '@/views/Sales/SalesAppointments';
import SalesPlanning from '@/views/Sales/SalesPlanning';
import SalesCardState from '@/views/Sales/SalesCardState';
import ProjectCardCommunication from '@/views/Projects/ProjectCardCommunication';

export default {
  name: 'SalesEditPage',
  components: {
    Breadcrumbs,

    ButtonBar,
    SalesCardOverview,
    SalesCustomer,
    SalesDocuments,
    SalesTimeline,
    SalesEmail,
    // SalesHistory,
    // SalesAppointments,
    SalesPlanning,
    SalesCardState,
    ProjectCardCommunication,
  },
  props: {
    projectNumber: {
      required: false,
      default: null,
    },
    // referer: { default: '', type: String },
  },
  data() {
    return {
      number: null,
      user: {},
      buttonsDisabled: false,
      filteredStorageSystems: [],
      filteredSolarPanels: [],
      filteredProjectAttachmentTypes: [],
      tabIndex: 0,
      referer: null,
      selectedAppointment: null,
      editor: InlineEditor,
      editorConfig: {},
    };
  },
  filters: {
    toDate(value) {
      // console.log(value);
      return value ? new Date(value) : null;
    },
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isClient', 'getCurrentUser']),
    ...mapGetters([
      'getFlashMessage',
      'getProject',
      'getProjectByNumber',
      'getPowerCompanies',
      'getSolarPanels',
      'getStorageSystems',
      'getMeasurementPointOperators',
      'getEnumValues',
    ]),
    buttons() {
      return {
        showPreviousButton:
          this.isAdmin && this.project && this.project.previousProjectNumber ? true : false,
        showNextButton:
          this.isAdmin && this.project && this.project.nextProjectNumber ? true : false,
        showDeleteButton: false,
        // this.isAdmin && this.project && this.project.number !== '_new' ? true : false,
      };
    },
    installationTeamPayedAt: {
      get() {
        return this.$calendarFormat(this.project.installationTeamPayedAt);
      },
      set(value) {
        this.project.installationTeamPayedAt = value;
      },
    },
    employerPayedAt: {
      get() {
        return this.$calendarFormat(this.project.employerPayedAt);
      },
      set(value) {
        this.project.employerPayedAt = value;
      },
    },
    invoiceSentToEmployerAt: {
      get() {
        return this.$calendarFormat(this.project.invoiceSentToEmployerAt);
      },
      set(value) {
        this.project.invoiceSentToEmployerAt = value;
      },
    },

    documentationSentToEmployerAt: {
      get() {
        return this.$calendarFormat(this.project.documentationSentToEmployerAt);
      },
      set(value) {
        this.project.documentationSentToEmployerAt = value;
      },
    },
    netRegistrationSentAt: {
      get() {
        return this.$calendarFormat(this.project.netRegistrationSentAt);
      },
      set(value) {
        this.project.netRegistrationSentAt = value;
      },
    },
    injectionConfirmationReceivedAt: {
      get() {
        return this.$calendarFormat(this.project.injectionConfirmationReceivedAt);
      },
      set(value) {
        this.project.injectionConfirmationReceivedAt = value;
      },
    },
    operationRegistrationSentAt: {
      get() {
        return this.$calendarFormat(this.project.operationRegistrationSentAt);
      },
      set(value) {
        this.project.operationRegistrationSentAt = value;
      },
    },
    completionMessageSentAt: {
      get() {
        return this.$calendarFormat(this.project.completionMessageSentAt);
      },
      set(value) {
        this.project.completionMessageSentAt = value;
      },
    },
    powerCompanyClearanceAt: {
      get() {
        return this.$calendarFormat(this.project.powerCompanyClearanceAt);
      },
      set(value) {
        this.project.powerCompanyClearanceAt = value;
      },
    },

    solarPlantOverallCapacity() {
      if (this.project.solarPlant.storageSystem && this.project.solarPlant.storageSystem.battery) {
        return (
          this.project.solarPlant.storageSystem.batteryCount *
          this.project.solarPlant.storageSystem.battery.capacity
        );
      }
      return 'N/A';
    },
    calculateSolarPlantOverallPower() {
      return solarPlantOverallPower(this.project);
    },
    optionsYesNo() {
      return yesNo();
    },
    project() {
      return this.getProjectByNumber(this.number);
    },
    isNew() {
      return this.project.id === '_new';
    },
    projectNumberByClientContext() {
      let number = '';
      if (this.project.number === '_new') {
        number = this.$t('_new');
      }

      if (
        this.isClient &&
        this.project.number &&
        this.project.number !== '_new' &&
        this.project.clientProjectNumber
      ) {
        number = this.project.clientProjectNumber.toString().padStart(4, '0');
      }

      if (this.isAdmin && this.project.number && this.project.number !== '_new') {
        number = this.project.number.toString().padStart(4, '0');
      }
      return number;
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: 'Leads', route: { name: 'SalesEditPage' } },
        {
          name: this.projectNumberByClientContext,
          // this.project.number && this.project.number !== '_new'
          //   ? 'Projekt ' + this.project.number + ' bearbeiten'
          //   : 'erstellen',
        },
      ];
    },
    isUploadDisabled() {
      return this.selectedProjectAttachmentType === null ? true : false;
    },
    /**
     * @todo DRY
     */
    invalidFeedbackNA() {
      return 'Voraussetzung NA';
    },
  },
  methods: {
    ...mapActions([
      'initProject',
      'fetchProjects',
      'fetchProjectByNumber',
      'updateProject',
      'createProject',
      'deleteProject',
      'fetchInstallationTeams',
      'fetchInstallationTypes',
      'fetchOriginalInstallationTypes',
      'fetchPowerCompanies',
      'fetchSolarPanels',
      'fetchStorageSystems',
      'mailToCustomerInitialInfo',
      'uploadProjectAttachment',
      'deleteProjectAttachment',
      'fetchMeasurementPointOperators',
      'fetchEnumValues',
      'fetchReportTemplateImages',
    ]),

    isDisabled(e) {
      e.preventDefault();
    },
    yesNoColor(state) {
      return getYesNoStateColor(state);
    },
    projectStateColor(state) {
      return getProjectStateColor(state);
    },

    /** @todo changes to project are lost */
    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
      if (payload.parentType === 'layoutPlanAttachments') {
        await this.fetchProjectByNumber(this.number);
      }
    },
    async onUploadProjectAttachments(payload) {
      for (const file of payload.files) {
        await this.uploadProjectAttachment({
          file,
          parentId: payload.parentId,
          parentType: payload.parentType,
        });
      }
      if (payload.parentType === 'layoutPlanAttachments') {
        await this.fetchProjectByNumber(this.number);
      }
    },
    onStorageSystemSearch(query) {
      this.filteredStorageSystems = [];
      this.getStorageSystems.map((storageSystem) => {
        if (
          storageSystem.manufacturer.toLowerCase().indexOf(query) >= 0 ||
          storageSystem.type.toLowerCase().indexOf(query) >= 0
        ) {
          this.filteredStorageSystems.push(storageSystem);
        }
      });
    },
    onSolarPanelSearch(query) {
      this.filteredSolarPanels = [];
      this.getSolarPanels.map((solarPanel) => {
        if (
          solarPanel.manufacturer.toLowerCase().indexOf(query) >= 0 ||
          solarPanel.type.toLowerCase().indexOf(query) >= 0
        ) {
          this.filteredSolarPanels.push(solarPanel);
        }
      });
    },

    onConfirmAppointment(installationAppointment) {
      this.selectedAppointment = installationAppointment;
      /** change tab to E-Mail */
      this.tabIndex = this.isClient ? 7 : 11;
    },

    async setup() {
      await this.fetchInstallationTeams();
      await this.fetchInstallationTypes();
      await this.fetchOriginalInstallationTypes();
      await this.fetchEnumValues('ProjectAttachmentType');
      await this.fetchEnumValues('EmployerProjectAttachmentType');
      await this.fetchPowerCompanies();
      await this.fetchSolarPanels();
      await this.fetchStorageSystems();
      await this.fetchMeasurementPointOperators();
      await this.fetchReportTemplateImages();

      this.filteredStorageSystems = this.getStorageSystems;
      this.filteredSolarPanels = this.getSolarPanels;
      this.filteredProjectAttachmentTypes = this.isClient
        ? this.getEnumValues('EmployerProjectAttachmentType')
        : this.getEnumValues('ProjectAttachmentType');
    },

    async setupClientContext() {},

    async setupAdminContext() {},

    /**
     * check requirementsValidState to show mark input fields
     * @todo DRY
     */
    requirementsValidState(field) {
      if (!this.project.netRegistrationRequirements) return false;

      const requirement = this.project.netRegistrationRequirements.find(
        (requirement) => requirement.name === field
      );
      return requirement && requirement.fullfilledState === 'YES' ? true : false;
    },
  },
  async mounted() {
    if (this.isClient) {
      await this.setupClientContext();
    }
    if (this.isAdmin) {
      await this.setupAdminContext();
    }
    await this.setup();

    if (!this.project && this.projectNumber) {
      this.number = this.projectNumber;
      await this.fetchProjectByNumber(this.number).then((errors) => {
        if (errors && errors.length > 0 && errors[0].extensions.code === 'FORBIDDEN') {
          this.$router.push('/403');
        }
      });
    } else {
      this.initProject();
      this.number = '_new';
    }

    if (this.isClient) {
      this.project.client = this.getCurrentUser.client;
    }

    /**
     * Go to installation tab if coming from RouteSchedulingPage
     */
    if (this.referer && this.referer.name === 'RouteSchedulingPage') {
      this.tabIndex = 4; // change tab to Installation
    }
  },

  /**
   * if only parameters of the route have changed the component will be reused
   * we have to update all the data before the route updates
   * @param to
   * @param from
   * @param next
   */
  async beforeRouteUpdate(to, from, next) {
    // console.log('Route params', to.params);
    // console.log('Current project number', this.projectNumber);
    if (to.params.projectNumber && to.params.projectNumber !== this.projectNumber) {
      this.$store.commit('SET_PROJECT_DEFAULT_STATE');
      this.number = to.params.projectNumber;
      await this.fetchProjectByNumber(this.number);
      await this.setup();
      next();
    } else {
      next(false);
    }
  },

  /**
   * Save referer to current view
   */
  beforeRouteEnter(to, from, next) {
    // console.log('beforeRouteEnter');
    next((vm) => {
      vm.referer = from;
    });
  },
};
</script>

<style scoped lang="scss">
.spinner {
  top: 4px;
  // right: 400px;
  position: relative;
}

.customer-card {
  max-width: 50rem;
}
.solar-plant-card {
  max-width: 35rem;
}
.calculations-card {
  max-width: 25rem;
}
.solar-plant-data-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
  row-gap: 0.7em;
}
.calculations-grid-container {
  display: grid;
  grid-template-columns: 5fr 1fr;
  column-gap: 1em;
  row-gap: 0.7em;
}
.form-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
  row-gap: 1em;
}
.form-grid-container div {
  padding: 0.4em;
}
.form-grid-container-left {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 1em;
  row-gap: 1em;
}
.form-grid-container-left div {
  padding: 0.4em;
}
.form-grid-container-left .label {
  text-align: right;
}

.trash-installation-appointment {
  text-align: right;
}
.trash-installation-appointment i {
  cursor: pointer;
}
legend {
  position: relative;
}
.button-right {
  position: absolute;
  display: inline-block;
  right: 0;
  top: 2px;
}

li > .badge {
  font-size: 0.8em;
  padding: 3px 4px 4px 4px;
}
.ck-content {
  min-height: 80px;
}
</style>
